<template>
  <div>
    <v-card>
      <v-card-title> Stock list {{ entitySheetId }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="7">
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="handleNewReceiveModal"
              >
                New receive
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="handleNewReleaseModal"
              >
                New release
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="handleNewTransfer"
              >
                New transfer
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="handleNewAdjustment"
              >
                New adjustment
              </v-btn>
            </v-col>

            <v-col cols="6" sm="3" md="2">
              <v-select
                v-model="listFilters.storage"
                :items="computedStorages"
                item-text="name"
                label="Select storage"
                return-object
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="3" md="2">
              <v-checkbox
                v-model="listFilters.onHand"
                label="On hand"
              ></v-checkbox>
            </v-col>
            <v-col cols="4" sm="3" md="2">
              <v-checkbox
                v-model="listFilters.damaged"
                label="Damaged"
              ></v-checkbox>
            </v-col>
            <v-col cols="4" sm="3" md="2">
              <v-checkbox
                v-model="listFilters.remainder"
                label="Remainder"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!-- <pre>
                {{ computedItemCollection }}
              </pre> -->

              <v-data-table
                :headers="headers"
                :items="computedItemCollection"
                :search="search"
                :sort-by="['no_of_pcs', 'name']"
                sort-desc="true"
                @click:row="openEntityListDialog"
              >
                <template v-slot:item.quantity="{ item }">
                  <span> {{ item.totalQuantity }} {{ item.quantityUnit }}</span>
                </template>

                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Are you sure you want to delete this
                          item?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <div v-if="receiveModalData.dialog">
            <ReceiveModalForm
              ref="receivetransferlist"
              key="transferModalReceivekey"
              :receiveModalData="Object.assign({}, receiveModalData)"
              :receivedData="{
                storage_id: null,
                trNumber: null,
              }"
              @closeModalForm="handleCloseReceiveModalForm"
              @saveModalForm="handleSaveReceiveModalForm"
              @new="handleNew"
            ></ReceiveModalForm>
          </div>

          <div v-if="releaseModalData.dialog">
            <ReleaseModalForm
              key="stockItemsModalReleasekey"
              :releaseModalData="Object.assign({}, releaseModalData)"
              :receivedData="{
                storage_id: null,
                trNumber: null,
              }"
              @closeModalForm="handleCloseReleaseModalForm"
              @saveModalForm="handleSaveReleaseModalForm"
              @new="handleNewReleaseModal"
            ></ReleaseModalForm>
          </div>

          <div v-if="transferModalData.dialog">
            <TransferModalForm
              :modalData="transferModalData"
              :permissions="permissions"
              @closeModalForm="handleCloseTransferModalForm"
              @saveModalForm="handleSaveTransferModalForm"
              @new="handleNewTransfer"
            ></TransferModalForm>
          </div>
          <div v-if="adjustmentModalData.dialog">
            <AdjustmentModalForm
              :modalData="adjustmentModalData"
              :permissions="permissions"
              @closeModalForm="handleCloseAdjustmentModalForm"
              @saveModalForm="handleSaveAdjustmentModalForm"
              @new="handleNewAdjustment"
            ></AdjustmentModalForm>
          </div>

          <v-row justify="center">
            <EntitySheetDialog
              :entitySheetId="entitySheetId"
              :entityDialog="entityDialog"
              @handleCloseEntitySheetDialog="handleCloseEntitySheetDialog"
            >
            </EntitySheetDialog>
          </v-row>

          <v-row justify="center">
            <v-dialog
              v-model="dialogEntityList"
              persistent
              width="1440px"
              scrollable
            >
              <v-card>
                <v-card-title>
                  <v-col cols="12" sm="12" md="12">
                    <span class="text-h5" v-if="selectedStockList"
                      >{{ selectedStockList.reference }} -
                      {{ selectedStockList.name }}</span
                    >
                    <v-btn
                      @click="dialogEntityList = false"
                      style="float: right; cursor: pointer"
                      icon
                      color="#26223c"
                    >
                      <v-icon> mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-card-title>
                <v-card-text>
                  <v-tabs
                    v-model="tab"
                    background-color="#232341"
                    centered
                    dark
                    icons-and-text
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-1">
                      Entity list
                      <v-icon>mdi-chart-line</v-icon>
                    </v-tab>
                    <v-tab href="#tab-2">
                      Stock forecast
                      <v-icon>mdi-playlist-check</v-icon>
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item :key="1" :value="'tab-1'">
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="4" sm="3" md="2">
                              <v-checkbox
                                v-model="listFiltersInModal.onHand"
                                label="On hand"
                                @change="calculateEntityList"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="4" sm="3" md="2">
                              <v-checkbox
                                v-model="listFiltersInModal.damaged"
                                label="Damaged"
                                @change="calculateEntityList"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="4" sm="3" md="2">
                              <v-checkbox
                                v-model="listFiltersInModal.remainder"
                                label="Remainder"
                                @change="calculateEntityList"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="6" sm="3" md="2">
                              <v-select
                                v-model="listFiltersInModal.storage"
                                :items="computedStorages"
                                item-text="name"
                                label="Select storage"
                                return-object
                                @change="calculateEntityList"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-data-table
                                :headers="entityListHeaders"
                                :items="entityList"
                                class="elevation-1"
                                group-by="quantity"
                                show-group-by
                                :items-per-page="-1"
                                hide-default-footer
                                @click:row="handleOpenEntitySheetDialog"
                              >
                                <template
                                  v-slot:group.header="{
                                    group,
                                    headers,
                                    toggle,
                                    isOpen,
                                    items,
                                  }"
                                >
                                  <td :colspan="headers.length">
                                    <v-btn
                                      @click="toggle"
                                      small
                                      icon
                                      :ref="group"
                                      :data-open="isOpen"
                                    >
                                      <v-icon v-if="isOpen"
                                        >mdi-chevron-up</v-icon
                                      >
                                      <v-icon v-else>mdi-chevron-down</v-icon>
                                    </v-btn>
                                    {{ selectedStockList.reference }}
                                    {{ selectedStockList.name }} - {{ group
                                    }}{{ selectedStockList.quantityUnit }} -
                                    {{ items.length }} pcs
                                  </td>
                                </template>
                                <template v-slot:item.quantityUnit="{ item }">
                                  <span>
                                    {{ item.quantity }}
                                    {{ selectedStockList.quantityUnit }}</span
                                  >
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item :key="2" :value="'tab-2'">
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" v-if="showChart">
                              <VueApexCharts
                                height="350"
                                :options="chartOptions"
                                :series="series"
                              ></VueApexCharts>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-data-table
                                :headers="forecastListHeaders"
                                :items="forecastList"
                                class="elevation-1"
                              >
                                <template #header="{ props: { headers } }">
                                  <thead class="v-data-table-header">
                                    <tr>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th style="max-width: 100px">
                                        <v-select
                                          v-model="typeFilter"
                                          :items="['Select', ...typeCollection]"
                                          item-text="salesPersonName"
                                          item-value="salesPersonName"
                                        ></v-select>
                                      </th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                </template>
                                <template v-slot:item.name="{ item }">
                                  <span>{{ selectedStockList.name }}</span>
                                </template>
                                <template
                                  v-slot:item.transaction_number="{ item }"
                                >
                                  <router-link
                                    :to="
                                      urls[
                                        item.transaction_type.replaceAll(
                                          '\\',
                                          ''
                                        )
                                      ] + item.transaction_id
                                    "
                                    target="_blank"
                                  >
                                    {{ item.transaction_number }}
                                  </router-link>
                                </template>
                                <template v-slot:item.quantity="{ item }">
                                  <span>
                                    {{ item.quantity }}
                                    {{ selectedStockList.quantityUnit }}</span
                                  >
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogEntityList = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
        <!-- <pre>
          {{ computedStorages }}
        </pre> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ApiService from "@/core/services/api.service";

import ReleaseModalForm from "../releases/ReleaseModalForm";

import ReceiveModalForm from "../receives/ReceiveModalForm";

import TransferModalForm from "../transfers/TransferModalForm";

import AdjustmentModalForm from "../adjustments/AdjustmentModalForm";

import EntitySheetDialog from "@/view/components/EntitySheetDialog";

import VueApexCharts from "vue-apexcharts";

import { URL } from "@/view/components/EntitySheetDialog";

import Swal from "sweetalert2";

export const ENDPOINT = "stock/itemEntity/";

const initialListFilters = {
  storage: { id: "All", name: "All" },
  onHand: false,
  damaged: false,
  remainder: false,
};

export default {
  name: "StockItems",
  components: {
    VueApexCharts,
    ReleaseModalForm,
    ReceiveModalForm,
    TransferModalForm,
    AdjustmentModalForm,
    EntitySheetDialog,
  },
  data() {
    return {
      loader: false,
      urls: URL,
      tab: null,
      showAll: false,
      search: "",
      permissions: [],
      headers: [
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "No of pcs",
          value: "no_of_pcs",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
      ],

      entityListHeaders: [
        {
          text: "Entity ID",
          value: "item_entity_id",
          groupable: false,
        },
        {
          text: "Created at",
          value: "created_at",
          groupable: false,
        },
        {
          text: "Item name",
          value: "name",
          groupable: false,
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Quantity unit",
          value: "quantityUnit",
          groupable: false,
        },
        {
          text: "State",
          value: "state",
          groupable: false,
        },
        {
          text: "Storage",
          value: "storage",
          groupable: false,
        },
      ],

      forecastListHeaders: [
        {
          text: "Scheduled date",
          value: "scheduled_date",
        },
        {
          text: "Reference",
          value: "reference_number",
        },
        {
          text: "Item name",
          value: "name",
        },
        {
          text: "Transaction number",
          value: "transaction_number",
        },
        {
          text: "Transaction type",
          value: "transactionType",
          filter: (value) => {
            if (this.typeFilter == "Select") return true;
            return value == this.typeFilter;
          },
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Storage",
          value: "storage",
        },
        {
          text: "Linked entity",
          value: "item_entity_id",
        },
        {
          text: "Parent ID",
          value: "parent_item_entity_id",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },

      releaseModalData: {
        dialog: false,
        editedId: null,
      },

      receiveModalData: {
        dialog: false,
        editedId: null,
      },

      transferModalData: {
        dialog: false,
        editedId: null,
      },

      adjustmentModalData: {
        dialog: false,
        editedId: null,
      },

      // editedItem: Object.assign({}, initialFormData),

      listFilters: Object.assign({}, initialListFilters),
      listFiltersInModal: Object.assign({}, initialListFilters),

      storages: [],
      dialogDelete: false,
      dialogEntityList: false,
      selectedStockList: null,
      selectedItem: null,
      entityList: [],
      forecastList: [],
      typeFilter: "Select",
      series: null,
      entitySheetId: null,
      entityDialog: false,

      showChart: false,
      chartOptions: {
        colors: ["#26223c"],
        chart: {
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "stepline",
        },
        markers: {
          size: 5,
        },
        title: {
          text: "",
          align: "left",
          style: {
            fontSize: "14px",
          },
        },
        xaxis: {
          type: "date",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            // style: {
            //   colors: '#000000',
            // },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          colors: "#26223c",
          // opacity: 0.9
        },
        tooltip: {
          x: {
            show: false,
            format: "yyyy-MM-dd",
          },
          fixed: {
            enabled: false,
            position: "topRight",
          },
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30,
            },
          },
          padding: {
            left: 20,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      "stockItemCollection",
      "itemCollection",
      "storageCllection",
    ]),

    typeCollection() {
      return this.forecastList.map((item) => {
        return item.transactionType;
      });
    },

    computedItemCollection() {
      // let itemCollection = this.stockItemCollection;
      let computedItems = [];

      this.itemCollection.forEach((item) => {
        let stockItemsArray = this.stockItemCollection.filter((stockItem) => {
          if (stockItem.item_id != item.id) return false;

          let isFiltered = true;
          if (
            this.listFilters.storage.id != stockItem.storage_id &&
            this.listFilters.storage.id != "All"
          ) {
            isFiltered = false;
          }

          let filterStatusArray = [];

          if (this.listFilters.onHand) filterStatusArray.push(4);
          if (this.listFilters.damaged) filterStatusArray.push(9);
          if (this.listFilters.remainder) filterStatusArray.push(3);

          if (
            filterStatusArray.length > 0 &&
            !filterStatusArray.includes(stockItem.status)
          ) {
            isFiltered = false;
          }

          return isFiltered;
        });
        let no_of_pcs = stockItemsArray.length;

        if (no_of_pcs > 0) {
          let totalQuantity = this.roundToTwo(
            stockItemsArray
              .map((stItem) => {
                return stItem.quantity;
              })
              .reduce((a, b) => a + b)
          );

          let quantityUnit = this.$store.getters.getQuantityUnitByID(
            item.quantity_unit_id
          );
          let itemObject = {
            id: item.id,
            reference: item.reference,
            name: item.name,
            no_of_pcs: no_of_pcs,
            totalQuantity: totalQuantity,
            quantityUnit: quantityUnit ? quantityUnit.name : "",
          };
          computedItems.push(itemObject);
        } else if (
          !this.listFilters.onHand &&
          !this.listFilters.damaged &&
          !this.listFilters.remainder &&
          this.listFilters.storage.id == "All"
        ) {
          let quantityUnit = this.$store.getters.getQuantityUnitByID(
            item.quantity_unit_id
          );
          let itemObject = {
            id: item.id,
            reference: item.reference,
            name: item.name,
            no_of_pcs: 0,
            totalQuantity: 0,
            quantityUnit: quantityUnit ? quantityUnit.name : "",
          };
          computedItems.push(itemObject);
        }
      });

      return computedItems;
    },

    computedStorages() {
      if (this.stockItemCollection.length == 0) return [];
      let storagesIds = [null];

      this.stockItemCollection.forEach((item) => {
        if (!storagesIds.includes(item.storage_id) && item.storage_id) {
          storagesIds.push(item.storage_id);
        }
      });
      if (storagesIds.length > 0) {
        return [{ id: "All", name: "All" }].concat(
          this.$store.getters.getStoragesByIds(storagesIds)
        );
      }

      return [];
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions([
      "fetchStockItem",
      "fetchItem",
      "fetchStorage",
      "fetchQuantityUnit",
    ]),

    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },

    calculateEntityList() {
      return new Promise((resolve) => {
        let computedEntityList = this.stockItemCollection.filter(
          (stockItem) => {
            let isFiltered = true;
            if (
              this.listFiltersInModal.storage.id != stockItem.storage_id &&
              this.listFiltersInModal.storage.id != "All"
            ) {
              isFiltered = false;
            }

            let filterStatusArray = [];

            if (this.listFiltersInModal.onHand) filterStatusArray.push(4);
            if (this.listFiltersInModal.damaged) filterStatusArray.push(9);
            if (this.listFiltersInModal.remainder) filterStatusArray.push(3);

            if (
              filterStatusArray.length > 0 &&
              !filterStatusArray.includes(stockItem.status)
            ) {
              isFiltered = false;
            }

            return isFiltered;
          }
        );

        this.entityList = computedEntityList
          .filter((i) => i.item_id == this.selectedStockList.id)
          .map((i) => {
            let item = this.$store.getters.getItemByID(i.item_id);
            i.name = item.name;
            i.state = this.$t("ADJUSTMENT.status_" + i.status);
            i.storage = this.$store.getters.getStorageByID(i.storage_id)
              ? this.$store.getters.getStorageByID(i.storage_id).name
              : "";
            i.quantityUnit = this.$store.getters.getQuantityUnitByID(
              item.quantity_unit_id
            ).name;
            return i;
          });

        resolve();
      });
    },

    openEntityListDialog(selectedItem) {
      this.chartOptions.title.text = "";
      this.showChart = false;
      this.listFiltersInModal = Object.assign({}, this.listFilters);
      this.selectedStockList = selectedItem;
      this.tab = "tab-1";
      ApiService.get("stock/itemEntity/forecast/graph/" + selectedItem.id)
        .then(({ data }) => {
          this.series = [{ name: "", data: [] }];

          let xArray = Object.keys(data);
          let yArray = Object.values(data);

          xArray.forEach((item, index) => {
            this.series[0].data.push(
              Object.assign({}, { x: xArray[index], y: yArray[index] })
            );
          });

          this.chartOptions.title.text = this.selectedStockList.quantityUnit;

          this.series[0].name = ""; //this.selectedStockList.name
          this.showChart = true;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "The entity is reserved!",
            icon: "error",
          });
        });

      ApiService.get("stock/itemEntity/forecast/list/" + selectedItem.id).then(
        ({ data }) => {
          this.forecastList = data.map((item) => {
            item.transactionType = this.$t(
              "TRANSACTION_TYPE." + item.transaction_type.replaceAll("\\", "")
            );
            return item;
          });
        }
      );

      this.calculateEntityList().then(() => {
        this.dialogEntityList = true;
      });
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleNewReleaseModal() {
      this.releaseModalData.editedId = null;
      this.releaseModalData.dialog = true;
    },

    handleSaveReleaseModalForm() {
      this.fetchStockItem();
      this.handleCloseModalForm();
    },

    handleCloseReleaseModalForm() {
      this.releaseModalData.dialog = false;
      this.releaseModalData.editedId = null;
    },

    handleNewReceiveModal() {
      this.receiveModalData.editedId = null;
      this.receiveModalData.dialog = true;
    },

    handleSaveReceiveModalForm() {
      this.fetchStockItem();
      this.handleCloseReceiveModalForm();
    },

    handleCloseReceiveModalForm() {
      this.receiveModalData.dialog = false;
      this.receiveModalData.editedId = null;
    },

    handleNewTransfer() {
      this.transferModalData.editedId = null;
      this.transferModalData.dialog = true;
    },

    handleSaveTransferModalForm() {
      this.fetchStockItem();
      this.handleCloseTransferModalForm();
    },

    handleCloseTransferModalForm() {
      this.transferModalData.dialog = false;
    },

    handleNewAdjustment() {
      this.adjustmentModalData.editedId = null;
      this.adjustmentModalData.dialog = true;
    },

    handleSaveAdjustmentModalForm() {
      this.fetchStockItem();
      this.handleCloseModalForm();
    },

    handleCloseAdjustmentModalForm() {
      this.adjustmentModalData.dialog = false;
      this.adjustmentModalData.editedId = null;
    },
    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    handleCloseModalForm() {
      this.series = null;
      this.tab = "tab-1";
      this.entitySheetId = null;
      this.modalData.dialog = false;
      this.fetchStockItem();

      if (this.$route.name !== "stockItems")
        this.$router.push({ name: "stockItems" });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    handleOpenEntitySheetDialog(item) {
      this.entitySheetId = item.item_entity_id;
      this.entityDialog = true;
    },

    handleCloseEntitySheetDialog() {
      this.entityDialog = false;
      this.entitySheetId = null;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchStockItem();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    closeDelete() {
      // this.editedItem = Object.assign({}, initialFormData);
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/stock/stockItems/${item.id}`);
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "stock.itemEntity" + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("itemEntity") > -1
        );
      }
    },
  },

  async mounted() {
    this.fetchStockItem();
    this.fetchItem();
    this.fetchStorage();
    this.setPermissions();
    this.fetchQuantityUnit();
  },
};
</script>
