<template>
  <v-dialog v-model="entityDialog" persistent max-width="960px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Entity sheet - ID: {{ entitySheetId }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-simple-table v-if="entitySheet">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Entity ID</th>
                      <th class="text-left">Created at</th>
                      <th class="text-left">Reference</th>
                      <th class="text-left">Item name</th>
                      <th class="text-left">Serial number</th>
                      <th class="text-left">Quantity</th>
                      <th class="text-left">Original quantity</th>
                      <th class="text-left">Quantity unit</th>
                      <th class="text-left">State</th>
                      <th class="text-left">Storage</th>
                      <th class="text-left">Delivery date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ entitySheet.item_entity_id }}</td>
                      <td>{{ entitySheet.created_at }}</td>
                      <td>{{ entitySheet.reference_number }}</td>
                      <td>
                        {{
                          $helpers.getTranslated(entitySheet.translations).name
                        }}
                      </td>
                      <td>{{ entitySheet.serial_number }}</td>
                      <td>{{ entitySheet.quantity }}</td>
                      <td>{{ entitySheet.original_quantity }}</td>
                      <td>
                        {{
                          $helpers.getTranslated(
                            entitySheet.quantity_unit_translations
                          ).name
                        }}
                      </td>
                      <td>
                        {{ $t("ADJUSTMENT.status_" + entitySheet.status) }}
                      </td>
                      <td>{{ entitySheet.storage }}</td>
                      <td>{{ entitySheet.delivery_date }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-simple-table v-if="entitySheet" class="mt-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Linked movement</th>
                      <th class="text-left">Scheduled date</th>
                      <th class="text-left">Parent Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in entitySheet.transactions"
                      :key="item.name"
                    >
                      <td>
                        <router-link
                          :to="
                            urls[item.transaction_type.replaceAll('\\', '')] +
                            item.transaction_id
                          "
                          target="_blank"
                        >
                          {{ item.transaction_number }}
                        </router-link>
                        <!-- {{item}}}} -->
                      </td>
                      <td>{{ item.scheduled_date }}</td>
                      <td>{{ item.parent_id }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('handleCloseEntitySheetDialog')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export const URL = {
  ModulesStockEntitiesTransfer: "/stock/transfers/",
  ModulesSalesEntitiesSalesOrder: "/sales/salesorders/",
  ModulesTransportEntitiesTransportOrder: "/transport/transportOrders/",
  ModulesManufactureEntitiesProject: "/manufacture/projects/",
  ModulesProcurementEntitiesPurchaseOrder: "/procurement/purchaseOrder/",
};

export default {
  name: "EntitySheetDialog",

  props: ["entitySheetId", "entityDialog"],

  data() {
    return {
      entitySheet: null,
      urls: URL,
    };
  },

  computed: {
    ...mapGetters(["statuses"]),
  },

  watch: {
    entitySheetId(val) {
      if (val) {
        ApiService.get("stock/itemEntity/" + val).then(({ data }) => {
          this.entitySheet = data;
        });
      }
    },
  },
};
</script>
